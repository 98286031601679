import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import Logo from 'assett/icons/logo.svg';
import useDialogConfirm, { VERSION } from 'hooks/DialogConfirm.hook';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { RootState } from 'store/store';
import CardMedia from 'styles/card/CardMedia';
import Container from 'styles/container/Container';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { PaymentPathnames } from 'types/Payment';
import { TRANSLATION_KEYS } from 'utils/constants';
import { FooterDesktop } from '../FooterDesktop/FooterDesktop';
import Sidebar from '../Sidebar/Sidebar.component';
import Breadcrumbs from './Breadcrumbs.component';

export default function SharedLayout() {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const location = useLocation();
	const theme = useTheme();
	const confirmDialog = useDialogConfirm();

	const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const { id } = useParams();
	const { claimData } = useSelector((store: RootState) => store.claim);

	const paths = location.pathname.split('/').map(e => {
		if (decodeURI(e) === id) {
			if (!!id) {
				if (location.pathname.includes(PaymentPathnames.VIEW_PAYMENT_HISTORY)) {
					return `${tClientLabels('payment.breadcrumb')} #${id}`;
				} else if (!location.pathname.includes('check-and-submit')) {
					return `${tClientLabels('claim.breadcrumb')} #${
						claimData ? claimData.externalClaimId : id
					}`;
				}
			}
			return id;
		}

		if (
			location.pathname.includes(PaymentPathnames.EXPLANATION_OF_PAYMENT) ||
			location.pathname.includes(PaymentPathnames.GROUP_INVOICE_ASSESSMENT) ||
			location.pathname.includes(PaymentPathnames.ASSESSMENT_FEE_INVOICE) ||
			location.pathname.includes(id?.replace('|', '%7C') as string)
		) {
			return `${tClientLabels('payment.breadcrumb')} #${id} `;
		}

		return tClientLabels(`${e}.pageTitle`);
	});

	useEffect(
		() => {
			setWindowHeight(Math.max(windowHeight, window.innerHeight));
		},
		// eslint-disable-next-line
		[window.innerHeight],
	);

	return (
		<Container
			sx={{ height: !desktop ? `${windowHeight}px` : '100vh', overflow: 'hidden' }}
		>
			<Sidebar />

			<Stack width={'100%'}>
				<Stack direction={'row'} justifyContent={'space-between'}>
					<Stack sx={{ mt: { sm: 6.25, lg: 8.75 } }}>
						<Typography variant="title2">
							{location.pathname
								.split('/')
								.map(
									(s: string, index: number) =>
										index === paths.length - 1 && paths[index],
								)
								.map(l => (typeof l === 'string' ? l.split('|')[0] : l))}
						</Typography>

						<Breadcrumbs claimId={claimData.externalClaimId} />
					</Stack>
					<Stack alignItems={'flex-end'} sx={{ mt: { sm: 6.25, lg: 8.75 } }}>
						<Box width="48px" height="60px">
							<CardMedia
								component="img"
								image={Logo}
								onClick={() => {
									confirmDialog.open(VERSION);
								}}
							/>
						</Box>
					</Stack>
				</Stack>
				<Stack
					sx={{
						height: `calc(${windowHeight}px - ${!!desktop ? '240px' : '204px'})`,
						width: `calc(100vw - ${!!desktop ? 'auto' : '180px'})`,
					}}
				>
					<Outlet />
				</Stack>

				<FooterDesktop />
			</Stack>
		</Container>
	);
}
