import { useTranslate } from '@tolgee/react';
import { Paper, Stack, Typography } from 'styles';
import palette from 'styles/theme/Palette';
import { IDocumentStructure } from 'types/Document';
import { TRANSLATION_KEYS } from 'utils/constants';

type Props = {
	documents: IDocumentStructure[];
	selectedDocId: number | undefined;
	onSelectDocument: (id: number) => void;
};

const DocumentsList = ({
	documents,
	selectedDocId,
	onSelectDocument,
}: Props) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	const handleClick = (id: number) => {
		onSelectDocument(id);
	};

	return (
		<Stack gap={'10px'}>
			{documents.map(doc => (
				<Paper
					key={doc.id}
					elevation={0}
					onClick={() => handleClick(doc.id)}
					sx={{
						paddingX: '20px',
						paddingY: '21px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						'&:hover': {
							backgroundColor: palette.primary3.light,
						},
						border:
							doc.id === selectedDocId
								? `1px solid ${palette.primary.main}`
								: `1px solid ${palette.white.main}`,
					}}
				>
					<Stack gap={1}>
						<Typography variant="bodyLink">{`${doc.attributes.title}${doc.attributes.attachment.data[0].attributes.ext}`}</Typography>
						<Typography variant="value3">
							{`${tClientLabels('commons.fileSize')}:
								${doc.attributes.attachment.data[0].attributes.size} kB`}
						</Typography>
					</Stack>
				</Paper>
			))}
		</Stack>
	);
};

export default DocumentsList;
