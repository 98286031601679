import { useTranslate } from '@tolgee/react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/store';
import { Typography } from 'styles';
import { TRANSLATION_KEYS } from 'utils/constants';

const MemberDoctorType = () => {
	const { member } = useSelector((store: RootState) => store.member);
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	const doctorTypology = () => {

		switch (member?.doctorTypologies.length) {
			case 1:
				return (
					tClientLabels('memberDetail.labels.limitationNoticeType1', { doctorType1: member?.doctorTypologies[0] })
				)
			case 2:
				return (
					tClientLabels('memberDetail.labels.limitationNoticeType2', { doctorType1: member?.doctorTypologies[0], doctorType2: member?.doctorTypologies[1] })
				)
			case 3:
				return (
					tClientLabels('memberDetail.labels.limitationNoticeType3', { doctorType1: member?.doctorTypologies[0], doctorType2: member?.doctorTypologies[1], doctorType3: member?.doctorTypologies[2] })
				)
			default:
				return <></>
		}
	}

	return (
		<>
			<Typography variant="body">
				{doctorTypology()}
			</Typography>
		</>

	);
};

export default MemberDoctorType;
