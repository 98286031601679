import { useTranslate } from '@tolgee/react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Stack, Typography } from 'styles';
import { TRANSLATION_KEYS } from 'utils/constants';

const ErrorPage: React.FC<{
	navigate?: NavigateFunction;
}> = ({ navigate }) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	return (
		<Stack
			height="100vh"
			alignItems="center"
			justifyContent="center"
			direction="column"
			gap="1rem"
		>
			<Typography variant="headline2">
				{tClientLabels('error.globalError')}
			</Typography>
			{!!navigate && (
				<Button onClick={() => navigate?.('/')} variant="contained">
					{tClientLabels('commons.buttons.returnToHome')}
				</Button>
			)}
		</Stack>
	);
};

export default ErrorPage;
