import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { PaymentDetailsType } from 'types/Payment';
import { TRANSLATION_KEYS } from 'utils/constants';
import { formatPrice } from 'utils/utils';
import { Box100, Box130 } from '../ClaimsTable.component';

const TableFooter = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const theme = useTheme();
	const { paymentDetails } = useSelector((store: RootState) => store.payment);
	return (
		<>
			{(paymentDetails.detailType === PaymentDetailsType.EOP ||
				paymentDetails.detailType === PaymentDetailsType.GROUP_INVOICE) && (
					<>
						<Divider />
						<Stack
							display={'flex'}
							flexDirection={'row'}
							justifyContent={'space-between'}
							alignItems={'flex-start'}
							alignSelf={'stretch'}
							gap={theme.spacing(1.25)}
						>
							<Box130>
								<Typography variant="value1">
									{tClientLabels('payment.claimTable.total')}
								</Typography>
							</Box130>
							<Box130></Box130>
							<Box100>
								<Typography variant="value1">
									{formatPrice(paymentDetails.total.totalCharges)}
								</Typography>
							</Box100>
							<Box100>
								<Typography variant="value1">
									{formatPrice(paymentDetails.total.contractualWriteOff)}
								</Typography>
							</Box100>
							<Box100>
								<Typography variant="value1">
									{formatPrice(paymentDetails.total.memberResponsible)}
								</Typography>
							</Box100>
							<Box100>
								<Typography variant="value1">
									{formatPrice(paymentDetails.total.claimPayment)}
								</Typography>
							</Box100>
							<Box100 borderRadius={1} bgcolor={theme.palette.grey5.main}>
								<Typography variant="value1">
									{formatPrice(paymentDetails.total.assessmentFee)}
								</Typography>
							</Box100>
							<Box100 borderRadius={1} bgcolor={theme.palette.grey5.main}>
								<Typography variant="value1">
									{formatPrice(paymentDetails.total.assessmentFeeTax)}
								</Typography>
							</Box100>
							<Box100>
								<Typography variant="value1">
									{formatPrice(paymentDetails.total.other)}
								</Typography>
							</Box100>
							{paymentDetails.detailType === PaymentDetailsType.EOP && (
								<Box100>
									<Typography variant="value1">
										{formatPrice(paymentDetails.total.netDue)}
									</Typography>
								</Box100>
							)}
							<Box100></Box100>
						</Stack>
					</>
				)}
		</>
	);
};

export default TableFooter;
