import useDialogConfirm, {
	DOCUMENTATION_INFO,
	SUBMIT_LATER,
} from 'hooks/DialogConfirm.hook';
import { FC, useState } from 'react';
import { Button, Checkbox, IconButton, Stack, Typography } from 'styles';

import { useTranslate } from '@tolgee/react';
import { ReactComponent as InfoIcon } from 'assett/icons/info_icon.svg';
import { submitClaim } from 'feature/claims/claimSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { IClaim } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';
import { PageProps } from './NewClaim.component';
import Documentation from './components/Documentation';
import DownloadAttestation from './components/DownloadAttestation';
import PriceEstimate from './components/PriceEstimate';

const Page2: FC<PageProps> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { formData, setFormData } = props;
	const dispatch = useDispatch<AppDispatch>();
	const dialogConfirm = useDialogConfirm();

	const [statusCalculation, setStatusCalculation] = useState<
		'beforeCalculation' | 'afterCalculation' | 'onEdit'
	>(
		formData.amount.components.find(el => !el.retailAmount)
			? 'beforeCalculation'
			: 'afterCalculation',
	);

	const handleClickInfo = () => {
		dialogConfirm.open(DOCUMENTATION_INFO);
	};

	return (
		<Stack gap="60px">
			<PriceEstimate
				formData={formData}
				setFormData={setFormData}
				status={statusCalculation}
				setStatus={setStatusCalculation}
			/>
			{['afterCalculation', 'onEdit'].includes(statusCalculation) ? (
				<Stack gap="40px">
					<Stack gap="20px">
						<Stack direction={'row'} alignItems={'center'} gap={'10px'}>
							<Typography variant="title6">
								{tClientLabels('newClaim.selectedPage.sectionTitile.uploadDoc')}
							</Typography>
							<IconButton
								onClick={handleClickInfo}
								disableFocusRipple
								disableRipple
								disableTouchRipple
							>
								<InfoIcon width={24} height={24} />
							</IconButton>
						</Stack>
						<DownloadAttestation />
						<Documentation formData={formData} />
					</Stack>

					<Stack gap="20px">
						<Typography
							variant="caption"
							style={{ whiteSpace: 'pre-line', fontSize: '12px' }}
						>
							{tClientLabels('newClaim.upload.warning')}
						</Typography>
						<Checkbox
							sx={{ left: '-9px' }}
							label={tClientLabels('newClaim.upload.uploadConsent')}
							checked={formData.uploadConsent || false}
							onChange={(_, value) => {
								setFormData((prev: IClaim) => ({
									...prev,
									uploadConsent: value,
								}));
							}}
						/>
					</Stack>

					<Stack alignItems="start" gap="20px" direction={'row'}>
						<Button
							variant="outlined"
							onClick={() => {
								dialogConfirm.open(SUBMIT_LATER);
							}}
						>
							{tClientLabels('newClaim.saveFinishLater')}
						</Button>
						<Button
							disabled={
								!formData.uploadConsent ||
								['beforeCalculation', 'onEdit'].includes(statusCalculation) ||
								!formData.prescription?.id ||
								!formData.receipt?.id ||
								(formData.rxChangeAttestation === 1 && !formData.rxAttestation?.id)
							}
							variant="contained"
							onClick={() => {
								dispatch(submitClaim(formData));
							}}
						>
							{tClientLabels('newClaim.submitClaim')}
						</Button>
					</Stack>
				</Stack>
			) : null}
		</Stack>
	);
};

export default Page2;
