import { FC, Fragment } from 'react';
import { BoxOverflow, IconButton, MarkdownRenderer, Typography } from 'styles';

import { useTranslate } from '@tolgee/react';
import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import useDialogConfirm from 'hooks/DialogConfirm.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Button from 'styles/button/Button';
import Dialog from 'styles/dialog/Dialog';
import Stack from 'styles/stack/Stack';
import { TRANSLATION_KEYS } from 'utils/constants';
import packageJson from '../../../../package.json';


const DialogConfirm: FC = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dialogConfirm = useDialogConfirm();

	const {
		open,
		title,
		content,
		caption,
		messageCode,
		labelCancel,
		labelConfirm,
		variantButtonCancel,
		multi,
		data,
		buttonClose,
	} = useSelector((store: RootState) => store.hooks.dialog);
	const { errorMessages } = useSelector(
		(store: RootState) => store.textsLocales,
	);
	const { type } = useSelector((store: RootState) => store.hooks.dialog);

	const contentMessage = content
		? tClientLabels(content)
		: errorMessages && messageCode && errorMessages[messageCode]
			? errorMessages[messageCode]
			: tClientLabels('dialog.confirm.error.content');

	return (
		<Dialog
			type={type}
			open={open}
			close={
				buttonClose ? (
					<IconButton onClick={() => dialogConfirm.close()}>
						<CloseDialog />
					</IconButton>
				) : null
			}
			variant="confirm"
			title={tClientLabels(`${title}`)}
			events={!!labelCancel || !!labelConfirm}
			actions={
				<Fragment>
					{
						labelCancel ? (
							<Button
								variant={variantButtonCancel}
								onClick={() => dialogConfirm.cancel()}
							>
								{tClientLabels(`${labelCancel}`)}
							</Button>
						) : null
					}
					{
						labelConfirm ? (
							<Button variant="contained" onClick={() => dialogConfirm.confirm()}>
								{tClientLabels(`${labelConfirm}`)}
							</Button>
						) : null
					}
				</Fragment >
			}
		>
			{!multi ? (
				<Stack gap={'20px'}>
					<Typography variant="body">
						{<MarkdownRenderer>{
							type === 'version' ? `${contentMessage} ${packageJson.version}` :
								contentMessage
						}</MarkdownRenderer>}
					</Typography>
					{caption && (
						<Typography variant="caption" color={theme => theme.palette.grey1.main}>
							{tClientLabels(`${caption}`)}
						</Typography>
					)}
				</Stack>
			) : (
				<BoxOverflow variant="y" sx={{ gap: '20px' }}>
					{data.map((e: any, index) => {
						return (
							<Stack key={index} gap={'16px'}>
								<Typography variant="title6">{e.title}</Typography>
								<Typography variant="body">
									<MarkdownRenderer>{e.content}</MarkdownRenderer>
								</Typography>
								{caption && (
									<Typography variant="caption" color={theme => theme.palette.grey1.main}>
										{e.caption}
									</Typography>
								)}
							</Stack>
						)
					})}
				</BoxOverflow>
			)}
		</Dialog >
	);
};

export default DialogConfirm;
