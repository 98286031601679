import { useDispatch } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';

import Button from 'styles/button/Button';
import ButtonLink from 'styles/button/ButtonLink';
import CardMedia from 'styles/card/CardMedia';
import DashboardImage from 'assett/icons/dashboard_image.svg';
import DashboardMenu from './Menu/DashboardMenu';
import { Dashboard_Go } from 'utils/links';
import { useMemo, useState } from 'react';
import { Props } from './DashboardPage.component';
import Stack from 'styles/stack/Stack';
import { resetClaims } from 'feature/claims/claimsSlice';
import { resetForm } from 'feature/form/formSlice';
import { useEffect } from 'react';
import { useLogout } from 'hooks/login.hook';
import { Box } from 'styles';
import { useTranslate } from '@tolgee/react';
import { TRANSLATION_KEYS } from 'utils/constants';

export const DashboardPageComponent: React.FC<Props> =
	function DashboardPageComponent(props = {}) {
		const [screenWidth, setScreenWidth] = useState(window.innerWidth);
		const theme = useTheme();
		const tablet = useMediaQuery(theme.breakpoints.down('lg'));
		const desktop = useMediaQuery(theme.breakpoints.up('lg'));
		const dispatch = useDispatch<any>();

		const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

		const hiddenImage = useMemo(() => {
			if (screenWidth < 1560) {
				return true;
			}
			return false;
		}, [screenWidth]);

		const calculateWidth = () => {
			if (screenWidth < 1560 && screenWidth > 1024) {
				return '80%';
			}
			return '100%';
		};

		const handleLogout = useLogout();

		useEffect(() => {
			dispatch(resetForm());
			dispatch(resetClaims());
		});

		useEffect(() => {
			const updateDimension = () => {
				setScreenWidth(window.innerWidth);
			};
			window.addEventListener('resize', updateDimension);

			return () => {
				window.removeEventListener('resize', updateDimension);
			};
		}, [screenWidth]);

		return (
			<Stack direction={'row'} justifyContent={'space-between'} mt={2}>
				<Stack
					width={{
						sm: !hiddenImage ? '60%' : calculateWidth(),
						lg: '60%',
					}}
					justifyContent={'space-between'}
					rowGap={!!desktop ? '70px' : '30px'}
					sx={{
						overflow: 'auto',
						overflowX: 'hidden',
					}}
					maxHeight="500px"
				>
					<DashboardMenu />
					<Stack
						direction="row"
						gap={!!tablet ? '18px' : '30px'}
						justifyContent="space-between"
						alignItems="center"
					>
						<Box width="100%">
							<Button variant="contained" onClick={handleLogout}>
								{tClientLabels('dashboard.buttons.logout')}
							</Button>
						</Box>
						<Stack width="100%" direction="row" gap="21px" justifyContent="flex-end">
							{Dashboard_Go(tClientLabels).map((e: any) => (
								<Box key={e.id} flexShrink={0}>
									<ButtonLink startIcon={e.icon} label={e.name} to={e.link} />
								</Box>
							))}
						</Stack>
					</Stack>
				</Stack>
				{!hiddenImage && (
					<Stack
						alignSelf={'flex-end'}
						maxWidth={{ lg: '556px' }}
						maxHeight={{ lg: '430px' }}
						width="30%"
					>
						<CardMedia component="img" image={DashboardImage} />
					</Stack>
				)}
			</Stack>
		);
	};
