import { useTranslate } from '@tolgee/react';
import {
	changePage,
	downloadPayments,
	setPaymentsIds,
} from 'feature/payments/paymentsSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Button from 'styles/button/Button';
import Pagination from 'styles/pagination/Pagination';
import Stack from 'styles/stack/Stack';
import { TRANSLATION_KEYS } from 'utils/constants';

const PaymentsFooter = () => {
	const dispatch = useDispatch<any>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { isDesktop } = useDevice();
	const { paymentsIds, pages, page, payments, paymentExcludeIds, downloadAll } =
		useSelector((store: RootState) => store.payments);

	const refreshPaymentIdsList = useMemo(
		() =>
			!payments.some(payment =>
				paymentExcludeIds.includes(payment.paymentRunId),
			) && downloadAll,
		[paymentExcludeIds, payments, downloadAll],
	);

	const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		value: number,
	) => {
		event.preventDefault();
		dispatch(changePage(value));
	};

	const handleClick = (type: 'PDF' | 'XLSX') => {
		dispatch(downloadPayments({ type: type }));
	};

	useEffect(() => {
		if (refreshPaymentIdsList)
			dispatch(
				setPaymentsIds(payments.map(e => e.paymentRunId + e.paymentRunDate)),
			);
	}, [dispatch, payments, refreshPaymentIdsList]);

	return (
		<Stack
			direction={'row'}
			position={'relative'}
			sx={{ my: !!isDesktop ? 4.25 : 3 }}
			alignItems={'center'}
			justifyContent={'space-between'}
		>
			<Stack
				minHeight="24px"
				direction={'row'}
				flexGrow={1}
				sx={{
					justifyContent: !!isDesktop ? 'center' : 'flex-start',
					alignItems: 'center',
				}}
			>
				{pages > 1 ? (
					<Pagination page={page} count={pages} onChange={handleChangePage} />
				) : null}
			</Stack>
			<Stack
				direction={'row'}
				position={'absolute'}
				right={0}
				sx={{
					gap: 2,
					alignContent: 'center',
					justifyContent: 'flex-end',
				}}
			>
				<Button
					type="button"
					variant="contained"
					disabled={paymentsIds.length > 0 ? false : true}
					fullWidth={false}
					onClick={() => handleClick('XLSX')}
				>
					{tClientLabels('view-claims.exportButton.XLSX')}
				</Button>
				<Button
					type="button"
					variant="contained"
					disabled={paymentsIds.length > 0 ? false : true}
					fullWidth={false}
					onClick={() => handleClick('PDF')}
				>
					{tClientLabels('view-claims.exportButton.PDF')}
				</Button>
			</Stack>
		</Stack>
	);
};

export default PaymentsFooter;
