import {
	DrawerProps,
	List,
	StackProps,
	Theme,
	styled,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FC, Fragment, useState } from 'react';
import {
	Dashboard_Actions,
	Dashboard_ManageAccount,
	Home,
	Logout,
} from 'utils/links';

import { CSSObject } from '@emotion/react';
import { useTranslate } from '@tolgee/react';
import { ReactComponent as ChevronLeftIcon } from 'assett/icons/chevron_left_icon.svg';
import { ReactComponent as ChevronRightIcon } from 'assett/icons/chevron_right_icon.svg';
import { useLogout } from 'hooks/login.hook';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store/store';
import Box from 'styles/box/Box';
import Divider from 'styles/divider/Divider';
import Drawer from 'styles/drawer/Drawer';
import Stack from 'styles/stack/Stack';
import { TRANSLATION_KEYS } from 'utils/constants';
import ListItemButton from './ListItemButton.component';

const DRAWER_WIDTH = 336;
const DRAWER_WIDTH_TABLET = 315;

const openedMixin = (theme: Theme, desktop: boolean): CSSObject => ({
	width: !!desktop ? DRAWER_WIDTH : DRAWER_WIDTH_TABLET,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	boxShadow: '3px 3px 12px 0px rgba(0, 0, 0, 0.05)',
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme, desktop: boolean): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	boxShadow: '3px 3px 12px 0px rgba(0, 0, 0, 0.05)',
	'&.MuiStack-root>.MuiStack-root>.MuiDivider-root': {
		width: 32,
	},
	width: `calc(${theme.spacing(8.5)} + 1px)`,
	[theme.breakpoints.up('lg')]: {
		width: `calc(${theme.spacing(10)} + 1px)`,
	},
});

type DrawerStyleProps = DrawerProps & {
	desktop: boolean;
};

const StyledDrawer = styled(Drawer, {
	shouldForwardProp: prop => !['open', 'desktop'].includes(String(prop)),
})<DrawerStyleProps>(({ theme, open, desktop }) => ({
	width: !!desktop ? DRAWER_WIDTH : DRAWER_WIDTH_TABLET,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme, desktop),
		'& .MuiDrawer-paper': openedMixin(theme, desktop),
	}),
	...(!open && {
		...closedMixin(theme, desktop),
		'& .MuiDrawer-paper': closedMixin(theme, desktop),
	}),
}));

type StackPropsStyled = StackProps & {
	open: boolean;
	desktop: boolean;
};

const StyledButtonSidebar = styled(Stack, {
	shouldForwardProp: prop => !['open', 'desktop'].includes(String(prop)),
})<StackPropsStyled>(({ theme, open, desktop }) => ({
	width: 24,
	height: '100vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexShrink: 0,
	marginLeft: -1,
	'& .Sidebar-Button': {
		boxShadow: '9px 1px 15px 0px rgba(0, 0, 0, 0.05)',
		borderTopRightRadius: 15,
		borderBottomRightRadius: 15,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: 'white',
		height: 129,
		zIndex: 10000,
		marginLeft: !open && !desktop ? -1 : 0,
		cursor: 'pointer',
	},
}));

const Sidebar: FC = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const [open, setOpen] = useState<boolean>(false);
	const navigate = useNavigate();
	const theme = useTheme();

	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const handleLogout = useLogout();

	const { features } = useSelector((store: RootState) => store.user);
	const featuresRole = features.map(el => el.code);

	return (
		<Fragment>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: open ? '100%' : '0%',
					height: `${window.innerHeight}px`,
					background: 'rgba(50, 50, 50, 0.25)',
					opacity: open ? 0.6 : 0,
					zIndex: 99,
				}}
			/>
			<Box position={'fixed'} left={0} zIndex={100}>
				<Box sx={{ position: 'fixed', display: 'flex' }} role="presentation">
					<StyledDrawer desktop={desktop} variant="permanent" open={open}>
						<Stack
							height={`${window.innerHeight}px`}
							justifyContent={'space-between'}
						>
							<Stack
								flexGrow={1}
								sx={{ px: !!desktop ? 3 : 2.25, overflow: 'hidden' }}
							>
								<List sx={{ rowGap: 5 }}>
									{Home(tClientLabels).map((action: any) => (
										<ListItemButton
											key={action.id}
											open={open}
											action={action}
											onClick={() => navigate(action.link)}
											sx={{ mt: 6.5 }}
										/>
									))}
								</List>
								<Divider sx={{ my: 3.75 }} />
								<List sx={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
									{Dashboard_Actions(tClientLabels)
										.filter(link => link.feature.find(el => featuresRole.includes(el)))
										.map(action => (
											<ListItemButton
												key={action.id}
												open={open}
												action={action}
												onClick={() => navigate(action.link)}
											/>
										))}
								</List>
								<Divider sx={{ my: 3.75 }} />
								<List sx={{ rowGap: 5, display: 'flex', flexDirection: 'column' }}>
									{Dashboard_ManageAccount(tClientLabels)
										.filter(link => link.feature.find(el => featuresRole.includes(el)))
										.map((action: any) => (
											<ListItemButton
												key={action.id}
												open={open}
												action={action}
												onClick={() => navigate(action.link)}
											/>
										))}
								</List>
							</Stack>
							<Stack flexGrow={0} sx={{ px: !!desktop ? 3 : 2.25 }}>
								<List>
									{Logout(tClientLabels).map(action => (
										<ListItemButton
											key={action.id}
											open={open}
											action={action}
											onClick={handleLogout}
											sx={{ my: 5 }}
										/>
									))}
								</List>
							</Stack>
						</Stack>
					</StyledDrawer>
					<StyledButtonSidebar open={open} desktop={desktop}>
						<span className="Sidebar-Button" onClick={() => setOpen(!open)}>
							{open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
						</span>
					</StyledButtonSidebar>
				</Box>
			</Box>
		</Fragment>
	);
};

export default Sidebar;
