import { Dashboard_Actions, Dashboard_ManageAccount } from 'utils/links';
import { useMediaQuery, useTheme } from '@mui/material';
import HomeButton from 'styles/button/HomeButton';
import { RootState } from 'store/store';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { useSelector } from 'react-redux';
import { useTranslate } from '@tolgee/react';

const DashboardMenu: React.FC = () => {
	const { t: tClientLabels } = useTranslate('clientLabels');
	const theme = useTheme();
	const tablet = useMediaQuery(theme.breakpoints.down('lg'));
	const { features } = useSelector((store: RootState) => store.user);
	const featuresRole = features.map(el => el.code);

	return (
		<Stack direction={'row'} gap={!!tablet ? '18px' : '40px'} width="100%">
			<Stack rowGap={!!tablet ? 1.87 : 1.9} width={'100%'}>
				<Typography variant="title6" sx={{ mb: 0.5, pl: 2 }}>
					{tClientLabels('dashboard.actions')}
				</Typography>
				{Dashboard_Actions(tClientLabels)
					.filter(link => link.feature.find(el => featuresRole.includes(el)))
					.map((e: any) => (
						<HomeButton
							startIcon={e.icon}
							label={e.name}
							to={e.link}
							disabled={!e.show}
							key={e.id}
						/>
					))}
			</Stack>
			<Stack rowGap={!!tablet ? 1.87 : 1.9} width={'100%'}>
				<Typography variant="title6" sx={{ mb: 0.5, pl: 2 }}>
					{tClientLabels('dashboard.manageAccount')}
				</Typography>
				{Dashboard_ManageAccount(tClientLabels)
					.filter(link => link.feature.find(el => featuresRole.includes(el)))
					.map((e: any) => (
						<HomeButton
							startIcon={e.icon}
							label={e.name}
							to={e.link}
							disabled={!e.show}
							key={e.id}
						/>
					))}
			</Stack>
		</Stack>
	);
};

export default DashboardMenu;
