import { useTranslate } from '@tolgee/react';
import { ReactComponent as ChevronBreadCrumbIcon } from 'assett/icons/chevron_breadcrumb_icon.svg';
import { ReactComponent as ChevronBreadCrumbIconTablet } from 'assett/icons/chevron_breadcrumb_icon_tablet.svg';
import useDevice from 'hooks/DesktopDevice.hook';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BreadcrumbsMui from 'styles/breadcrumbs/Breadcrumbs';
import Button from 'styles/button/Button';
import Typography from 'styles/typography/Typograpy';
import { PaymentPathnames } from 'types/Payment';
import { TRANSLATION_KEYS } from 'utils/constants';

interface Props {
	claimId: string;
}

const Breadcrumbs = (props: Props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const pathnames = location.pathname.split('/');
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { isDesktop } = useDevice();

	const { id, claimId } = useParams();

	const formatBreadcrumb = (route: string, index: number) => {
		// if we are inside the claims and the view is the single claim
		if (
			pathnames[1] === 'view-claims' &&
			index > 1 &&
			index === pathnames.length - 1
		) {
			return `${tClientLabels('claim.breadcrumb')} #${props.claimId ? props.claimId : route
				}`;
		}

		// if we are inside the payments list
		if (pathnames[1] === PaymentPathnames.VIEW_PAYMENT_HISTORY) {
			// we are inside the assessment fee invoice view
			if (index === 4) {
				return tClientLabels('assessment-fee-invoice.pageTitle');
			}

			if (index === 2) {
				return `${tClientLabels('payment.details.ticketTitle')}${route.split('|')[0]
					}`;
			}

			// we are inside a payment detail
			if (index > 2 && !parseInt(pathnames[index])) {
				return route;
			}

			// we are inside the specific payment
			if (index > 1 && parseInt(pathnames[2])) {
				return `${tClientLabels('payment.breadcrumb')} #${route}`;
			}

			// we are inside the list or the details
			return route;
		}

		// none of the other cases
		return route;
	};

	return (
		<BreadcrumbsMui
			sx={{ cursor: 'pointer', mt: 2.5 }}
			separator={
				isDesktop ? <ChevronBreadCrumbIcon /> : <ChevronBreadCrumbIconTablet />
			}
		>
			{pathnames
				.map(e => {
					if (decodeURI(e) === claimId) {
						return claimId;
					}
					return decodeURI(e) === id ? id : tClientLabels(`${e}.pageTitle`);
				})
				.map((route: any, index: number) => {
					if (
						index === 3 &&
						route === tClientLabels('assessment-fee-invoice.pageTitle')
					) {
						return null;
					}
					if (index === 0) {
						return (
							<Button
								variant="link"
								sx={{ minWidth: 0 }}
								onClick={() => navigate('/')}
								key={index}
							>
								<Typography variant="value3" sx={{ color: 'grey2.main' }}>
									{'Home'}
								</Typography>
							</Button>
						);
					}
					return (
						<Button
							variant="link"
							sx={{ minWidth: 0 }}
							onClick={() => {
								const path = [];

								for (let i = 1; i <= index; i++) {
									path.push(`/${pathnames[i]}`);
								}
								navigate(path.join(''));
							}}
							key={index}
						>
							<Typography
								variant="value3"
								sx={{
									color: index === pathnames.length - 1 ? 'primary.main' : 'grey2.main',
								}}
							>
								{formatBreadcrumb(route, index)}
							</Typography>
						</Button>
					);
				})}
		</BreadcrumbsMui>
	);
};

export default Breadcrumbs;
