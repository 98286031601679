import { useDispatch, useSelector } from 'react-redux';
import { IMemberSubscriptions, IMembers } from 'types/Members';
import { formatDayjsForBE, getShortDate } from 'utils/utils';

import { useTranslate } from '@tolgee/react';
import { ReactComponent as ChevronRightIcon } from 'assett/icons/chevron_right_icon.svg';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { getMemberDetails } from 'feature/members/memberSlice';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store/store';
import Box from 'styles/box/Box';
import IconButton from 'styles/button/IconButton';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { TRANSLATION_KEYS } from 'utils/constants';

dayjs.extend(isBetween);

const ListMembers = () => {
	const { members } = useSelector((store: RootState) => store.members);
	const { dateOfService } = useSelector((store: RootState) => store.form);
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const navigate = useNavigate();
	const dispatch = useDispatch<any>();
	const getMemberDetail = (member: IMembers, subscriptionId: number) => {
		dispatch(
			getMemberDetails({
				subscriptionId: subscriptionId,
				dateOfService: formatDayjsForBE(dateOfService),
				callback: () => {
					navigate(`/check-and-submit/${member.fullName}`);
				},
			}),
		);
	};

	return (
		<Box>
			<Typography variant="title5" sx={{ mb: { sm: 1.875, md: 2.5 } }}>
				{tClientLabels('check-and-submit.membersListLabel.results')}
			</Typography>
			<Stack width="100%">
				<Stack
					direction={'row'}
					sx={{ height: 42, px: 2.5, py: 1.25, mb: 0.75 }}
					alignItems={'center'}
				>
					<Typography variant="bodyLink" sx={{ width: { sm: 160, lg: 180 } }}>
						{tClientLabels('check-and-submit.membersListLabel.memberName')}
					</Typography>
					<Typography variant="bodyLink" sx={{ width: { sm: 160, lg: 180 } }}>
						{tClientLabels('check-and-submit.membersListLabel.fiscalCode')}
					</Typography>
					<Typography variant="bodyLink" sx={{ width: { sm: 160, lg: 180 } }}>
						{tClientLabels('check-and-submit.membersListLabel.relationship')}
					</Typography>
					<Typography variant="bodyLink" sx={{ width: { sm: 160, lg: 180 } }}>
						{tClientLabels('check-and-submit.membersListLabel.dateOfBirth')}
					</Typography>
				</Stack>
				<Stack rowGap={0.625}>
					{!!members?.hits.length &&
						members?.hits.map(member => {
							const {
								_id,
								firstName,
								lastName,
								fiscalCodeMasked,
								subscriptions,
								birthDate,
							} = member;
							return subscriptions.map((subs: IMemberSubscriptions) => {
								const { relationship, subscriptionId } = subs;
								return (
									<Paper
										variant="outlined"
										sx={{ height: 48, pl: 2.5, pr: 1.125, py: 1.25, cursor: 'pointer' }}
										key={`${_id}_${subscriptionId}`}
										onClick={() => getMemberDetail(member, subscriptionId)}
									>
										<Stack direction={'row'}>
											<Stack flexGrow={1} direction={'row'} alignItems={'center'}>
												<Typography variant="body" sx={{ width: { sm: 160, lg: 180 } }}>
													{firstName} {lastName}
												</Typography>
												<Typography variant="body" sx={{ width: { sm: 160, lg: 180 } }}>
													{fiscalCodeMasked}
												</Typography>
												<Typography variant="body" sx={{ width: { sm: 160, lg: 180 } }}>
													{relationship &&
														tClientLabels(
															`check-and-submit.membersListLabel.${relationship}`,
														)}
												</Typography>
												<Typography variant="body" sx={{ width: { sm: 160, lg: 180 } }}>
													{getShortDate(birthDate)}
												</Typography>
											</Stack>
											<Stack flexGrow={0}>
												<IconButton>
													<ChevronRightIcon />
												</IconButton>
											</Stack>
										</Stack>
									</Paper>
								);
							});
						})}
				</Stack>
			</Stack>
		</Box>
	);
};

export default ListMembers;
