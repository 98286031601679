import { setActiveButton, setLocationSelected } from 'feature/user/userSlice';
import { FC, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslate } from '@tolgee/react';
import { ILocation } from 'feature/search/searchSlice';
import { RootState } from 'store/store';
import MenuItem from 'styles/menu/MenuItem';
import MenuList from 'styles/menu/MenuList';
import Radio from 'styles/radio/Radio';
import Typography from 'styles/typography/Typograpy';
import { TRANSLATION_KEYS } from 'utils/constants';

const LocationList: FC = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { location, searchLocationValue } = useSelector(
		(store: RootState) => store.search,
	);
	const { locationSelected } = useSelector((store: RootState) => store.user);
	const progressIndicator = useSelector(
		(state: RootState) => state.progressIndicator,
	);

	const dispatch = useDispatch<any>();

	const handleChecked = (name: string) => {
		dispatch(setActiveButton(true));
		dispatch(setLocationSelected(name));
	};

	return (
		<Fragment>
			{!searchLocationValue.length ||
				progressIndicator?.isLoading ? null : location.length > 0 ? (
					<MenuList sx={{ height: 168, minHeight: 50, overflowY: 'auto', mt: 2 }}>
						{location?.map((e: ILocation) => (
							<MenuItem
								key={e._id}
								sx={{ pl: 0 }}
								disableRipple
								onClick={() => {
									handleChecked(e._source.providerId);
								}}
							>
								<Radio
									name="name"
									checked={e._source.providerId === locationSelected}
									sx={{ mr: 1.25 }}
								/>
								<Typography variant="value2">{e._source.name}</Typography>
							</MenuItem>
						))}
					</MenuList>
				) : (
				<Typography variant="value2">
					{tClientLabels('dialog.labelNoLocation.noLocation')}
				</Typography>
			)}
		</Fragment>
	);
};

export default LocationList;
