import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

import { useTranslate } from '@tolgee/react';
import { ReactComponent as DownloadIcon } from 'assett/icons/download.svg';
import useDialogConfirm, { GENERIC_ERROR } from 'hooks/DialogConfirm.hook';
import { useFileOpener } from 'hooks/downloader.hook';
import { Button } from 'styles';
import { TRANSLATION_KEYS } from 'utils/constants';

const DownloadAttestation: FC = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const fileOpener = useFileOpener();
	const dialogConfirm = useDialogConfirm();

	const { documents } = useSelector((store: RootState) => store.documentation);

	const attestation = documents.find(
		element => element.attributes.typology === 'attestation-form',
	);
	const url = attestation?.attributes?.attachment?.data[0].attributes.url;
	const name = attestation?.attributes?.attachment?.data[0].attributes.name;
	const URL_RAW = `${process.env.REACT_APP_API_URL}/cms${url}`;

	const downloadDoc = () => {
		if (url) {
			fileOpener(URL_RAW, name);
			return;
		}
		dialogConfirm.open(GENERIC_ERROR);
	};

	return (
		<Button
			variant="underline"
			sx={{ marginBottom: '20px', marginTop: '10px', width: 'fit-content' }}
			onClick={downloadDoc}
		>
			<DownloadIcon width={24} height={24} />
			{tClientLabels('newClaim.downloadAttestation')}
		</Button>
	);
};

export default DownloadAttestation;
