import { useMediaQuery } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Stack, Typography } from 'styles';
import InputNumber from 'styles/input/InputNumber';
import palette from 'styles/theme/Palette';
import { theme } from 'styles/theme/ThemeStyle';
import { Eye, IEyePrescription, IPrescription } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';
import { formatInputNumberValue } from 'utils/utils';

type prescriptionProps = {
	data: IPrescription;
	setData?: (arg0: IPrescription) => void;
	editable?: boolean;
	checkFields: boolean;
	setRequiredFilled: Dispatch<SetStateAction<boolean>>;
	presFilled: boolean;
	isOldPrescription?: boolean;
	isContactOnly: boolean;
};

const PrescriptionTable: FC<prescriptionProps> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const tablet = useMediaQuery(theme.breakpoints.up('md'));
	const {
		data,
		setData = () => { },
		editable = true,
		checkFields,
		setRequiredFilled,
		presFilled,
		isOldPrescription,
		isContactOnly,
	} = props;

	const handlerDisabledBtn = (data: IEyePrescription, index: number) => {
		let isBtnDisabled = false;

		const arrayFields = Object.keys(data);

		for (let field of arrayFields) {
			const key = field as keyof IEyePrescription;

			if (!desktop) {
				if (eyeFieldsForFilter[index].data.includes(field)) {
					if (data[key]) {
						isBtnDisabled = false;
						break;
					} else isBtnDisabled = true;
				}
			} else {
				if (data[key]) {
					isBtnDisabled = false;
					break;
				} else isBtnDisabled = true;
			}
		}
		return isBtnDisabled;
	};

	const prescriptionColumnsSplitted =
		isOldPrescription || isContactOnly
			? [filteredPrescriptionColumns]
			: tablet
				? [prescriptionColumns]
				: [prescriptionColumns.slice(0, 8), prescriptionColumns.slice(8)];

	const copyRow = (args: {
		data: IPrescription;
		columns: typeof prescriptionColumns;
		from: Eye;
		to: Eye;
	}) => {
		const { data, columns, from, to } = args;
		const toNewValue: IEyePrescription = {};
		columns.forEach(({ property }) => {
			toNewValue[property] = data[from][property];
		});

		const newData = { ...data, [to]: { ...data[to], ...toNewValue } };
		setData(newData);
	};

	const checkRequired = useCallback(() => {
		const prescriptionsColumns =
			isOldPrescription || isContactOnly
				? filteredPrescriptionColumns
				: prescriptionColumns;
		const requiredFields = prescriptionsColumns.filter(el => el.required);
		// const requiredFields = prescriptionColumns.filter(el => el.required);
		const emptyPropOd = requiredFields.findIndex(({ property: propKey }) =>
			typeof data.od[propKey] === 'string'
				? !data.od[propKey]?.trim()
				: !data.od[propKey],
		);
		const emptyPropOs = requiredFields.findIndex(({ property: propKey }) =>
			typeof data.os[propKey] === 'string'
				? !data.os[propKey]?.trim()
				: !data.os[propKey],
		);
		setRequiredFilled(emptyPropOd === -1 && emptyPropOs === -1);
		// eslint-disalbe-next-line
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.od, data.os, setRequiredFilled]);

	useEffect(() => {
		checkRequired();
		// eslint-disalbe-next-line
	}, [checkRequired]);

	const handleChange = (formattedValue: string, property: string, eye: Eye) => {
		const newData = {
			...data,
			[eye]: {
				...data[eye],
				[property]: formattedValue,
			},
		};
		setData(newData);
	};

	return (
		<>
			<Stack gap={3.75}>
				{prescriptionColumnsSplitted.map((cols, index) => (
					<Stack key={index} gap={2}>
						<Stack direction="row" gap={1} alignItems={'end'}>
							{/* PLACEHOLDER FOR EMPTY COLUMN */}
							<Typography variant="caption" width="37px" height={'100%'} />
							{cols.map(col => (
								<Typography
									key={`title-${col.title}`}
									variant="caption"
									width={'56px'}
									height={'100%'}
									sx={{ overflowWrap: 'break-word' }}
								>
									{col.title &&
										tClientLabels(`newClaim.prescription.eyesPrescriton.${col.title}`)}
								</Typography>
							))}
						</Stack>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent={{ sm: 'space-between', lg: 'normal' }}
						>
							<Stack direction="row" alignItems="center">
								<Typography variant="value1" width="45px" height={'100%'}>
									{tClientLabels(`newClaim.prescription.eyesPrescriton.OD`)}
								</Typography>
								<Stack direction="row" gap={1} alignItems="center">
									{cols.map(col => (
										<EyeInput
											handleInputNumberChange={formattedValue =>
												handleChange(formattedValue, col.property, Eye.od)
											}
											key={`OS-${col.property}`}
											disabled={!editable}
											value={formatInputNumberValue(data.od[col.property] || '') ?? ''}
											error={
												col.required &&
												checkFields &&
												(typeof data.od[col.property] === 'string'
													? !data.od[col.property]?.trim()
													: !data.od[col.property])
											}
										/>
									))}
								</Stack>
							</Stack>
							<Stack width="176px">
								<Button
									variant="contained"
									onClick={() => {
										copyRow({
											data,
											columns: cols,
											from: Eye.od,
											to: Eye.os,
										});
									}}
									sx={{ ml: 3, width: 'max-content' }}
									disabled={!editable || handlerDisabledBtn(data.od, index)}
								>
									{tClientLabels(`newClaim.prescription.eyesPrescriton.copyToOS`)}
								</Button>
							</Stack>
						</Stack>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent={{ sm: 'space-between', lg: 'normal' }}
						>
							<Stack direction="row" alignItems="center">
								<Typography variant="value1" width="45px" height={'100%'}>
									{tClientLabels(`newClaim.prescription.eyesPrescriton.OS`)}
								</Typography>
								<Stack direction="row" gap={1} alignItems="center">
									{cols.map(col => (
										<EyeInput
											key={`OS-${col.property}`}
											disabled={!editable}
											value={formatInputNumberValue(data.os[col.property] || '') ?? ''}
											handleInputNumberChange={formattedValue =>
												handleChange(formattedValue, col.property, Eye.os)
											}
											error={
												col.required &&
												checkFields &&
												(typeof data.os[col.property] === 'string'
													? !data.os[col.property]?.trim()
													: !data.os[col.property])
											}
										/>
									))}
								</Stack>
							</Stack>
							<Stack width="176px">
								<Button
									variant="contained"
									onClick={() => {
										copyRow({
											data,
											columns: cols,
											from: Eye.os,
											to: Eye.od,
										});
									}}
									sx={{ ml: 3, width: 'max-content' }}
									disabled={!editable || handlerDisabledBtn(data.os, index)}
								>
									{tClientLabels(`newClaim.prescription.eyesPrescriton.copyToOD`)}
								</Button>
							</Stack>
						</Stack>
					</Stack>
				))}
				{!presFilled && checkFields && (
					<Typography variant="bodyLink" color={palette.error.main}>
						{tClientLabels(`newClaim.prescription.fieldMandatoryMessage`)}
					</Typography>
				)}
			</Stack>
		</>
	);
};

const EyeInput = styled(InputNumber)`
	width: 56px;
	height: 56px;
	justify-content: center;
	.MuiInputBase-input {
		padding: 19px 10px 19px 10px;
	}
	.MuiInputBase-root {
		border: 1px solid ${palette.grey4.main};
	}
	.MuiInputBase-root.Mui-error {
		border: 1px solid ${palette.error.main};
	}
`;

const prescriptionColumns: {
	type: string;
	title: string;
	property: keyof IEyePrescription;
	required?: boolean;
}[] = [
		{
			type: 'textField',
			title: 'sphere',
			property: 'sphere',
			required: true,
		},
		{
			type: 'textField',
			title: 'cylinder',
			property: 'cylinder',
			required: true,
		},
		{
			type: 'textField',
			title: 'axis',
			property: 'axis',
			required: true,
		},
		{
			type: 'textField',
			title: 'addition',
			property: 'addition',
			required: true,
		},
		{
			type: 'textField',
			title: 'prismDioptresIO',
			property: 'prismDioptresIO',
		},
		{
			type: 'textField',
			title: 'prismDirectionIO',
			property: 'prismDirectionIO',
		},
		{
			type: 'textField',
			title: 'prismDioptresUD',
			property: 'prismDioptresUD',
		},
		{
			type: 'textField',
			title: 'prismDirectionUD',
			property: 'prismDirectionUD',
		},
		{
			type: 'textField',
			title: 'pd',
			property: 'pd',
			required: true,
		},
		{
			type: 'textField',
			title: 'height',
			property: 'height',
			required: true,
		},
		{
			type: 'textField',
			title: 'vertexFitted',
			property: 'vertexFitted',
		},
		{
			type: 'textField',
			title: 'faceFormTilt',
			property: 'faceFormTilt',
		},
		{
			type: 'textField',
			title: 'pantoscopicTilt',
			property: 'pantoscopicTilt',
		},
	];

const filteredPrescriptionColumns: {
	type: string;
	title: string;
	property: keyof IEyePrescription;
	required?: boolean;
}[] = [
		{
			type: 'textField',
			title: 'sphere',
			property: 'sphere',
			required: true,
		},
		{
			type: 'textField',
			title: 'cylinder',
			property: 'cylinder',
			required: true,
		},
		{
			type: 'textField',
			title: 'axis',
			property: 'axis',
			required: true,
		},
		{
			type: 'textField',
			title: 'addition',
			property: 'addition',
			required: true,
		},
		{
			type: 'textField',
			title: 'prismDioptresIO',
			property: 'prismDioptresIO',
		},
		{
			type: 'textField',
			title: 'prismDirectionIO',
			property: 'prismDirectionIO',
		},
		{
			type: 'textField',
			title: 'prismDioptresUD',
			property: 'prismDioptresUD',
		},
		{
			type: 'textField',
			title: 'prismDirectionUD',
			property: 'prismDirectionUD',
		},
	];

const eyeFieldsForFilter = [
	{
		data: [
			'sphere',
			'cylinder',
			'axis',
			'addition',
			'prismDioptresIO',
			'prismDirectionIO',
			'prismDioptresUD',
			'prismDirectionUD',
		],
	},
	{
		data: ['pd', 'height', 'vertexFitted', 'faceFormTilt', 'pantoscopicTilt'],
	},
];

export default PrescriptionTable;
